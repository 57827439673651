import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Admin from "./components/Admin";
import Main from "./components/Main";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Favorites from "./components/Favorites";
import Product from "./components/Product";
import Favproduct from "./components/Favproduct";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import db from "./func/db";
import firebase from "firebase";

const themeOptions = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#272624",
    },
    secondary: {
      main: "#e18500",
    },
    background: {
      default: "#dedede",
    },
  },
});

function App() {
  const [timer, settimer] = useState(0);
  const [checkbox, setcheckbox] = useState(true);

  const [enable, setenable] = useState(true);
  const [banned, setbanned] = useState(false);
  const [inp, setinp] = useState("");
  const [error, seterror] = useState(false);
  const [fnameinp, setfinp] = useState("");
  const [lnameinp, setlinp] = useState("");
  const [nameerror, setnameerror] = useState(false);

  const valchange = (e) => {
    setinp(e.target.value);
  };
  const fnamechange = (e) => {
    setfinp(e.target.value);
  };
  const lnamechange = (e) => {
    setlinp(e.target.value);
  };
  if (timer >= 90 && enable === true) {
    const cookies = new Cookies();
    setenable(false);
    cookies.set("access", "banned", {
      path: "/",
      expires: new Date(Date.now() + 60000000000),
    });
    setbanned(true);
  }
  useEffect(() => {
    const ext =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    const cookies = new Cookies();
    const accesscookie = cookies.get("access");

    if (ext !== "admin") {
      if (!accesscookie) {
        const intervalId = setInterval(() => {
          if (enable === true) {
            settimer((prevCount) => prevCount + 1);
          }
        }, 1000);

        return () => clearInterval(intervalId);
      } else if (accesscookie === "banned") {
        setbanned(true);
      } else {
        setbanned(false);
        setenable(false);
      }
    }
  }, []);

  const getemail = async (mail) => {
    const res = await db.collection("access").add({
      value: mail,
      fname: fnameinp,
      checkbox,
      lname: lnameinp,
      timestamp: firebase.firestore.FieldValue.serverTimestamp(),
    });
    return res;
  };

  const checkemail = () => {
    if (fnameinp.length < 2) {
      setnameerror(true);
      return;
    } else if (
      !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
        inp
      ) ||
      inp.trim().length < 6
    ) {
      seterror(true);
      return;
    } else {
      getemail(inp).then((res) => {
        const cookies = new Cookies();
        cookies.set("access", inp, {
          path: "/",
          expires: new Date(Date.now() + 60000000000),
        });
        setbanned(false);
      });
    }
  };
  console.log(checkbox);
  return (
    <ThemeProvider theme={themeOptions}>
      <div className="App">
        {banned === true ? (
          <div className="banneder">
            <div className="bannedcard">
              <h3>Sign up for updates from Jos & Sof</h3>
              <p>To view more designs, please enter your name and email</p>
              <div className="nameinps">
                <TextField
                  id="fname-basic"
                  value={fnameinp}
                  onChange={fnamechange}
                  error={nameerror}
                  required
                  className="nameinp"
                  label="First name"
                  variant="standard"
                />
                <TextField
                  id="lname-basic"
                  value={lnameinp}
                  className="nameinp"
                  onChange={lnamechange}
                  label="Last name"
                  variant="standard"
                />
              </div>
              <TextField
                id="standard-basic"
                value={inp}
                onChange={valchange}
                error={error}
                required
                label="email"
                className="emailinp"
                fullWidth
                variant="standard"
              />
              <br />
              <div className="ppcheckbox">
                <Checkbox
                  checked={checkbox}
                  onChange={(e) => {
                    setcheckbox(e.target.checked);
                  }}
                />
                <p className="pptext">
                  Uncheck the box if you prefer to not recieve emails with
                  exclusive promotions and news from the world of JosnSof. By
                  subscribing you agree to our{" "}
                  <a
                    target="_blank"
                    href="https://josnsof.com/pages/shipping-and-return"
                  >
                    Privacy Policy
                  </a>
                </p>
              </div>

              <Button variant="contained" onClick={checkemail}>
                Submit
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
        <Router>
          <Routes>
            <Route path="/admin" element={<Admin />} />
            <Route path="/favourites" element={<Favorites />} />
            <Route path="/:prid" element={<Product />} />
            <Route path="/favourites/:prid" element={<Favproduct />} />

            <Route path="/" element={<Main />} />
          </Routes>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
