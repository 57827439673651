import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyCfMPYTpSXr1wDSO27s8zgwS94tUxB_5VY",
    authDomain: "josnsof.firebaseapp.com",
    projectId: "josnsof",
    storageBucket: "josnsof.appspot.com",
    messagingSenderId: "1018401804434",
    appId: "1:1018401804434:web:7b368aa02baaa08991164b",
    measurementId: "G-SN1PHPRXHP"
});
const analytics = firebase.analytics();
const db=firebaseApp.firestore();

export default db;