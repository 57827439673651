import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "./Tutorial.css";
import p1 from "../Slides/1p.jpg";
import p2 from "../Slides/2p.jpg";
import p3 from "../Slides/3p.jpg";
import p4 from "../Slides/4p.jpg";
import p5 from "../Slides/5p.jpg";
import m1 from "../Slides/1m.jpg";
import m2 from "../Slides/2m.jpg";
import m3 from "../Slides/3m.jpg";
import m4 from "../Slides/4m.jpg";
import m5 from "../Slides/5m.jpg";
import { Button } from "@mui/material";
function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Tutorial(props) {
  const [current, setcurrent] = useState(0);
  const [{ width, height }, setWindowDimensions] = React.useState(
    getWindowDimensions()
  );
  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    adaptiveHeight: false,
    arrows: false,
    fade: true,
    afterChange: (current) => setcurrent(current),
  };
  const sliderRef = useRef();
  return (
    <div className="tutorialCont">
      <div className="tutorialmodal">
        <h1> How does it work? </h1>

        {width > 600 ? (
          <Slider {...settings} ref={sliderRef}>
            <img src={p1} className="pcslide" />
            <img src={p2} className="pcslide" />
            <img src={p3} className="pcslide" />
            <img src={p4} className="pcslide" />
            <img src={p5} className="pcslide" />
          </Slider>
        ) : (
          <Slider {...settings} ref={sliderRef}>
            <img src={m1} className="mslide" />
            <img src={m2} className="mslide" />
            <img src={m3} className="mslide" />
            <img src={m4} className="mslide" />
            <img src={m5} className="mslide" />
          </Slider>
        )}
        <Button
          className="tutorialbutton"
          variant="outlined"
          onClick={() => props.closefunc(false)}
        >
          Skip
        </Button>
        {current < 4 ? (
          <Button
            className="tutorialbutton"
            variant="contained"
            onClick={() => sliderRef.current.slickNext()}
          >
            Next
          </Button>
        ) : (
          <Button
            className="tutorialbutton"
            variant="contained"
            onClick={() => props.closefunc(false)}
          >
            Close
          </Button>
        )}
      </div>
    </div>
  );
}

export default Tutorial;
