import {  Button, Snackbar, TextField } from '@mui/material'
import React,{useState} from 'react'
import "./admin.css"
import MuiAlert from '@mui/material/Alert';
import Dashboard from './Dashboard';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  
function Admin() {
    const [unval, setunval]=useState("");
    const [pval, setpval]=useState("");
    const [username, setusername]=useState();
    const [error, seterror]=useState(false);

    const handleClose =()=>{
        seterror(false)
    }
    const uvalchange = (e)=>{
        setunval(e.target.value)
    }
    const pvalchange = (e)=>{
        setpval(e.target.value)
    }
    const loginbtnclicked = (e)=>{
        e.preventDefault();
        if(unval==="admin" && pval==="adminp"){
            setusername(unval)
        }else{
            seterror(true)
        }
    }

    

    return (
        <div className="logincont">

        {username!=="admin"?(
            
            <React.Fragment>
                <h1> Login </h1>
                <form autocomplete="off" >
                    <TextField id="outlined-basic" value={unval} onChange={uvalchange} label="Username" variant="standard" />
                    <TextField id="outlined-basic" value={pval} type="password" onChange={pvalchange} label="Password" variant="standard" />
                    <Button type="submit" onClick={loginbtnclicked} variant="contained">Login</Button>
                </form>
            </React.Fragment>

        ):(
            <Dashboard />
        )}
        <Snackbar open={error} anchorOrigin={{ vertical: "bottom", horizontal:"center" }}
            autoHideDuration={3000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" >
                    Username or passowrd is wrong
                </Alert>
        </Snackbar>
        </div>
    )
}

export default Admin
