import React from 'react'
import { useEffect,useState } from 'react'
import db from '../func/db';
import "./main.css";
import Cookies from 'universal-cookie';
import { ArrowBackIosRounded, FavoriteBorderRounded, FavoriteRounded } from '@mui/icons-material';
import "./fav.css";
import { Dialog, IconButton, Slide } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import FlipMove from 'react-flip-move';
import Product from './Product';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function Favorites() {
  const CloseTab = () => {
    window.opener = null;
    window.open("", "_self");
    window.close();
  };


    const [favs, setfavs] = useState([]);
    const [products, setproducts] = useState([]);
    const history = useNavigate();
    const [open, setOpen] = React.useState(false);

        const handleClickOpen = (prid) => {
            setOpen(prid);
        };

        const handleClose = () => {
            setOpen(false);
        };


    useEffect(() => {
        const cookies = new Cookies();
        const favcookie = cookies.get("favs",  { path: '/' })
        if(favcookie){
          const favcookielist=favcookie.split(",")
          setfavs([...favcookielist])
        }

        db.collection('products').onSnapshot(snapshot=>{
            setproducts(snapshot.docs.map(doc => ({ 
                id:doc.id,
                title:doc.data().title,
                desc:doc.data().desc,
                yt:doc.data().yt,
                mainimg:doc.data().mainpic,
                banner:doc.data().banner,
                guide:doc.data().guid,
                op:doc.data().op,
             })))
        })
      }, [])
      const addtofavorite =(id)=>{
        const cookies = new Cookies();
        const favcookie = cookies.get("favs",  { path: '/' })

        if(favcookie){
          if(favcookie.split(",").indexOf(id) > -1 ){
            const temocoo = favcookie.split(",");
            temocoo.splice(favcookie.split(",").indexOf(id), 1);
            cookies.set('favs', temocoo.join(","),  { path: '/' });
            const temp = [...favs]
            temp.splice(temp.indexOf(id), 1)
            setfavs([...temp]) 
          }else{
            cookies.set('favs', favcookie+`,${id}`,  { path: '/' });
            const temp = [...favs]
            temp.push(id)
            setfavs([...temp]) 
          }
        }else{
          cookies.set('favs', id,  { path: '/' });
          const temp = [...favs]
          temp.push(id)
          setfavs([...temp]) 
        }

      }


    return (
        <div className="favcont">
         <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            
        <Product handleClose={handleClose} page="fav" prid={open} />
      </Dialog>

            <div className="favheader">
                <IconButton onClick={CloseTab}> <ArrowBackIosRounded /> </IconButton> <h1>FAVOURITES</h1>
                <div />
            </div>
            <div className="favbody">
                <FlipMove staggerDelayBy={120} typeName={null} enterAnimation="fade">
                {products?.map(product=>{
                    if(favs.indexOf(product.id) > -1){
                        return(
                          <div className='favprs'>
                            <IconButton onClick={()=>addtofavorite(product.id)} className="favbtninfavitem" aria-label="like" color="error"> {favs.indexOf(product.id) > -1 ?(<FavoriteRounded />):(<FavoriteBorderRounded />)}</IconButton>
                              <Link to={`/favourites/${product.id}`}>

                              <div key={product.id} className="faveitem" style={{background:'url("'+product.mainimg+'")'}}>
                              </div>
                            </Link>
                          </div>

                        )
                    }
                })
                }
                </FlipMove>
            </div>
        </div>
    )
}

export default Favorites
