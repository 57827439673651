import {
  ArrowBackIosRounded,
  ArrowForward,
  ArrowForwardIosRounded,
  ArrowForwardRounded,
  ArrowRightOutlined,
  CloseRounded,
  Download,
  DownloadRounded,
  FavoriteBorderOutlined,
  FavoriteRounded,
  Rectangle,
} from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import {
  AppBar,
  Badge,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import db from "../func/db";
import "./Product.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Cookies from "universal-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Favproduct() {
  const history = useNavigate();
  const { prid } = useParams();
  const [product, setproduct] = useState({});
  const [Loading, setLoading] = useState(true);
  const [alldialog, setalldialog] = useState(false);
  const [detdialog, setdetdialog] = useState(false);
  const [detdet, setdetdet] = useState("");
  const [favs, setfavs] = useState([]);
  const downloadFile = (url) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = function (event) {
      //Create an `a` tag (since it has an `href` and a `download` attribute)
      var a = document.createElement("a");
      a.href = window.URL.createObjectURL(xhr.response);
      a.download = "JosnSof";
      a.style.display = "none";
      document.body.appendChild(a);
      a.click(); //Simulates a click event
      var blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();
  };
  useEffect(() => {
    const cookies = new Cookies();
    const favcookie = cookies.get("favs", { path: "/" });
    if (favcookie) {
      const favcookielist = favcookie.split(",");
      setfavs([...favcookielist]);
    }
  }, []);

  const getproduct = async () => {
    const res = await db.collection("products").doc(prid).get();
    return res;
  };
  useEffect(() => {
    getproduct()
      .then((res) => {
        setproduct({
          id: res.id,
          title: res.data().title,
          desc: res.data().desc,
          yt: `https://www.youtube-nocookie.com/embed/${
            res.data().yt
          }?controls=0&playlist=${res.data().yt}&rel=0&autoplay=1&loop=1`,
          mainimg: res.data().mainpic,
          banner: res.data().banner,
          guide: res.data().guid,
          op: res.data().op,
        });
      })
      .then(() => setLoading(false));
  }, []);
  const addtofavorite = (id) => {
    const cookies = new Cookies();
    const favcookie = cookies.get("favs", { path: "/" });

    if (favcookie) {
      if (favcookie.split(",").indexOf(id) > -1) {
        const temocoo = favcookie.split(",");
        temocoo.splice(favcookie.split(",").indexOf(id), 1);
        cookies.set("favs", temocoo.join(","), { path: "/" });
        const temp = [...favs];
        temp.splice(temp.indexOf(id), 1);
        setfavs([...temp]);
      } else {
        cookies.set("favs", favcookie + `,${id}`, { path: "/" });
        const temp = [...favs];
        temp.push(id);
        setfavs([...temp]);
      }
    } else {
      cookies.set("favs", id, { path: "/" });
      const temp = [...favs];
      temp.push(id);
      setfavs([...temp]);
    }
  };
  return (
    <div className="prcont">
      <Dialog
        open={alldialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setalldialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="alldialogcont">
          {product.op?.map((imageAsUrl, index) => {
            return (
              <div
                className="imgupload "
                onClick={() => {
                  setdetdialog(true);
                  setdetdet(imageAsUrl.ccimgurl);
                }}
              >
                <label>
                  <div
                    className="uploadbtn multiimg"
                    style={{ backgroundImage: `url(${imageAsUrl.imgUrl})` }}
                  ></div>
                  <p style={{ textAlign: "center" }}>{imageAsUrl.title}</p>
                </label>
              </div>
            );
          })}
        </div>
      </Dialog>
      <Dialog
        open={detdialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setdetdialog(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="mdialogheader">
          <IconButton
            onClick={() => setdetdialog(false)}
            edge="start"
            color="inherit"
            aria-label="close"
            sx={{ margin: 0, padding: 0 }}
          >
            <CloseRounded />
          </IconButton>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => downloadFile(detdet)}
            sx={{ margin: 0, padding: 0 }}
          >
            <DownloadRounded />
          </IconButton>
        </div>

        <img src={detdet} className="detimg" width="100%" height="auto" />
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={Loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <div className="prbar hideinprint">
        <Link to="/favourites">
          <div className="backbtnproduct">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              sx={{ margin: 0, padding: 0 }}
            >
              <ArrowBackIosRounded sx={{ fontSize: 15 }} />
            </IconButton>

            <p className="backtext">Back to favourites</p>
          </div>
        </Link>

        <div>
          <IconButton
            edge="start"
            color="inherit"
            onClick={window.print}
            aria-label="close"
          >
            <Download />
          </IconButton>

          <Link to="/favourites">
            <Badge
              className="iconav"
              color="primary"
              badgeContent={favs.length}
              showZero
            >
              <FavoriteRounded color="error" />
            </Badge>
          </Link>
        </div>
      </div>
      <div className="prbod">
        <div className="prtitle">
          <h2>{product.title}</h2>
          <p>{product.desc}</p>
        </div>
        <div className="praminimg bannerimg">
          <IconButton
            className="favicondetpr"
            onClick={() => addtofavorite(product.id)}
            aria-label="like"
            color="error"
          >
            {favs.indexOf(product.id) > -1 ? (
              <FavoriteRounded />
            ) : (
              <FavoriteBorderOutlined />
            )}
          </IconButton>
          <img src={product.banner} width="100%" height="auto" />
        </div>
        <br />
        <div className="prtitle">
          <h2>OUR GUIDE ON HOW TO TIE</h2>
        </div>
        <div className="praminimg">
          <img src={product.guide} width="100%" height="auto" />
        </div>
        <br />
        <div className="prytcont hideinprint">
          <div className="borderoverlay"></div>
          <iframe
            width="560"
            id="iframecont"
            height="315"
            src={product.yt}
            title={product.title}
            frameborder="0"
          ></iframe>
        </div>
      </div>
      <br />
      <h3>
        <i>RECOMMENDED BOWS AND TIES</i>
      </h3>
      <div className="opcont">
        {product.op?.map((imageAsUrl, index) => {
          if (index < 5) {
            return (
              <div
                className="imgupload "
                onClick={() => {
                  setdetdialog(true);
                  setdetdet(imageAsUrl.ccimgurl);
                }}
              >
                <label>
                  <div
                    className="uploadbtn multiimg"
                    style={{ backgroundImage: `url(${imageAsUrl.imgUrl})` }}
                  ></div>
                  <p style={{ textAlign: "center" }}>{imageAsUrl.title}</p>
                </label>
              </div>
            );
          }
        })}
        {product.op && product.op.length > 4 ? (
          <div
            className="imgupload seemorecont"
            onClick={() => setalldialog(true)}
          >
            <label>
              <div className="uploadbtn multiimg seemore">
                <ArrowForwardRounded fontSize="large" />
                <p>See more/</p>
                <p>not recommended</p>
              </div>
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Favproduct;
