import {
  Button,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import "./Designs.css";
import {
  AddPhotoAlternate,
  Cancel,
  CancelRounded,
  DeleteRounded,
} from "@mui/icons-material";
import db from "../func/db";
import firebase from "firebase";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Designs() {
  const [products, setproducts] = useState([]);
  const [editting, setedditing] = useState(false);
  const [open, setOpen] = React.useState(false);
  const allInputs = { imgUrl: "" };
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const [btn, setbtn] = useState(<AddPhotoAlternate className="uploadicon" />);
  const storage = firebase.storage();
  const handleImageAsFile = (e) => {
    handleFireBaseUpload(e.target.files[0]);
  };

  const handleFireBaseUpload = (imageAsFile) => {
    setbtn(<CircularProgress className="uploadicon" />);
    // async magic goes here...
    if (
      imageAsFile === "" ||
      !imageAsFile ||
      (imageAsFile.type !== "image/jpg" &&
        imageAsFile.type !== "image/png" &&
        imageAsFile.type !== "image/jpeg")
    ) {
      setImageAsUrl({ imgUrl: "" });
      setbtn(<AddPhotoAlternate className="uploadicon" />);
    } else {
      const date = new Date();

      const uploadTask = storage
        .ref(`/images/${date.getTime() + imageAsFile.name}`)
        .put(imageAsFile);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {},
        (err) => {
          console.log("uploaderror" + err);
          setbtn(<AddPhotoAlternate className="uploadicon" />);
        },
        () => {
          storage
            .ref("images")
            .child(date.getTime() + imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setImageAsUrl((prevObject) => ({
                ...prevObject,
                imgUrl: fireBaseUrl,
              }));
              setbtn("");
            });
        }
      );
    }
  };

  const guideallInputs = { imgUrl: "" };
  const [guideimageAsUrl, setguideImageAsUrl] = useState(guideallInputs);
  const [guidebtn, setguidebtn] = useState(
    <AddPhotoAlternate className="uploadicon" />
  );

  const guidehandleImageAsFile = (e) => {
    guidehandleFireBaseUpload(e.target.files[0]);
  };
  const guidehandleFireBaseUpload = (imageAsFile) => {
    setguidebtn(<CircularProgress className="uploadicon" />);
    // async magic goes here...
    if (
      imageAsFile === "" ||
      !imageAsFile ||
      (imageAsFile.type !== "image/jpg" &&
        imageAsFile.type !== "image/png" &&
        imageAsFile.type !== "image/jpeg")
    ) {
      setguideImageAsUrl({ imgUrl: "" });
      setguidebtn(<AddPhotoAlternate className="uploadicon" />);
    } else {
      const date = new Date();

      const uploadTask = storage
        .ref(`/images/${date.getTime() + imageAsFile.name}`)
        .put(imageAsFile);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {},
        (err) => {
          console.log("uploaderror" + err);
          setguidebtn(<AddPhotoAlternate className="uploadicon" />);
        },
        () => {
          storage
            .ref("images")
            .child(date.getTime() + imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setguideImageAsUrl((prevObject) => ({
                ...prevObject,
                imgUrl: fireBaseUrl,
              }));
              setguidebtn("");
            });
        }
      );
    }
  };

  const mainallInputs = { imgUrl: "" };
  const [mainimageAsUrl, setmainImageAsUrl] = useState(mainallInputs);
  const [mainbtn, setmainbtn] = useState(
    <AddPhotoAlternate className="uploadicon" />
  );
  const [titleinp, settitleinp] = useState("");
  const [descinp, setdescinp] = useState("");
  const [ytinp, setytinp] = useState("");
  const [orderinp, setorderinp] = useState(null);

  const handleorderchange = (e) => {
    setorderinp(e.target.value);
  };

  const mainhandleImageAsFile = (e) => {
    mainhandleFireBaseUpload(e.target.files[0]);
  };
  const mainhandleFireBaseUpload = (imageAsFile) => {
    setmainbtn(<CircularProgress className="uploadicon" />);
    // async magic goes here...
    if (
      imageAsFile === "" ||
      !imageAsFile ||
      (imageAsFile.type !== "image/jpg" &&
        imageAsFile.type !== "image/png" &&
        imageAsFile.type !== "image/jpeg")
    ) {
      setmainImageAsUrl({ imgUrl: "" });
      setmainbtn(<AddPhotoAlternate className="uploadicon" />);
    } else {
      const date = new Date();

      const uploadTask = storage
        .ref(`/images/${date.getTime() + imageAsFile.name}`)
        .put(imageAsFile);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {},
        (err) => {
          console.log("uploaderror" + err);
          setmainbtn(<AddPhotoAlternate className="uploadicon" />);
        },
        () => {
          storage
            .ref("images")
            .child(date.getTime() + imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setmainImageAsUrl((prevObject) => ({
                ...prevObject,
                imgUrl: fireBaseUrl,
              }));
              setmainbtn("");
            });
        }
      );
    }
  };

  const [opimageAsUrl, setopImageAsUrl] = useState([]);
  const [opbtn, setopbtn] = useState(
    <AddPhotoAlternate className="uploadicon" />
  );

  const ophandleImageAsFile = (e) => {
    ophandleFireBaseUpload(e.target.files[0]);
  };
  const ophandleFireBaseUpload = (imageAsFile) => {
    setopbtn(<CircularProgress className="uploadicon" />);
    // async magic goes here...
    if (
      imageAsFile === "" ||
      !imageAsFile ||
      (imageAsFile.type !== "image/jpg" &&
        imageAsFile.type !== "image/png" &&
        imageAsFile.type !== "image/jpeg")
    ) {
      setopbtn(<AddPhotoAlternate className="uploadicon" />);
    } else {
      const date = new Date();

      const uploadTask = storage
        .ref(`/images/${date.getTime() + imageAsFile.name}`)
        .put(imageAsFile);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {},
        (err) => {
          console.log("uploaderror" + err);
          setopbtn(<AddPhotoAlternate className="uploadicon" />);
        },
        () => {
          storage
            .ref("images")
            .child(date.getTime() + imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              setopImageAsUrl((prevObject) => [
                ...opimageAsUrl,
                { imgUrl: fireBaseUrl },
              ]);
              setopbtn(<AddPhotoAlternate className="uploadicon" />);
            });
        }
      );
    }
  };
  const handleccImageAsFile = (e, i) => {
    cchandleFireBaseUpload(e.target.files[0], i);
  };
  const cchandleFireBaseUpload = (imageAsFile, i) => {
    // async magic goes here...
    if (
      imageAsFile === "" ||
      !imageAsFile ||
      (imageAsFile.type !== "image/jpg" &&
        imageAsFile.type !== "image/png" &&
        imageAsFile.type !== "image/jpeg")
    ) {
    } else {
      const date = new Date();

      const uploadTask = storage
        .ref(`/images/${date.getTime() + imageAsFile.name}`)
        .put(imageAsFile);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {},
        (err) => {
          console.log("uploaderror" + err);
        },
        () => {
          storage
            .ref("images")
            .child(date.getTime() + imageAsFile.name)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              const temp = [...opimageAsUrl];
              temp[i] = { ...temp[i], ccimgurl: fireBaseUrl };
              setopImageAsUrl([...temp]);
            });
        }
      );
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handletitlechange = (e) => {
    settitleinp(e.target.value);
  };
  const handlemtchange = (e, i) => {
    const temp = [...opimageAsUrl];
    temp[i] = { ...temp[i], title: e.target.value };
    setopImageAsUrl([...temp]);
  };

  const handledescchange = (e) => {
    setdescinp(e.target.value);
  };
  const handleytchange = (e) => {
    setytinp(e.target.value);
  };
  const handleClose = () => {
    setedditing(false);
    settitleinp("");
    setdescinp("");
    setytinp("");
    setorderinp("");
    setopbtn(<AddPhotoAlternate className="uploadicon" />);
    setbtn(<AddPhotoAlternate className="uploadicon" />);
    setguidebtn(<AddPhotoAlternate className="uploadicon" />);
    setmainbtn(<AddPhotoAlternate className="uploadicon" />);
    setImageAsUrl({ imgUrl: "" });
    setmainImageAsUrl({ imgUrl: "" });
    setguideImageAsUrl({ imgUrl: "" });
    setopImageAsUrl([]);
    setOpen(false);
  };
  const deleteproduct = (id) => {
    db.collection("products").doc(id).delete();
  };
  const deleteitem = (idx) => {
    let temp = [...opimageAsUrl];
    temp.splice(idx, 1);
    setopImageAsUrl([...temp]);
  };
  const savetodb = () => {
    if (editting) {
      db.collection("products")
        .doc(editting)
        .update({
          title: titleinp,
          desc: descinp,
          yt: ytinp,
          mainpic: mainimageAsUrl.imgUrl,
          banner: imageAsUrl.imgUrl,
          guid: guideimageAsUrl.imgUrl,
          op: opimageAsUrl,
          order: parseInt(orderinp),
        })
        .then(() => {
          handleClose();
        });
    } else {
      db.collection("products")
        .add({
          title: titleinp,
          desc: descinp,
          yt: ytinp,
          mainpic: mainimageAsUrl.imgUrl,
          banner: imageAsUrl.imgUrl,
          guid: guideimageAsUrl.imgUrl,
          op: opimageAsUrl,
          order: parseInt(orderinp),
        })
        .then(() => {
          handleClose();
        });
    }
  };

  useEffect(() => {
    db.collection("products")
      .orderBy("order", "asc")
      .onSnapshot((snapshot) => {
        setproducts(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            desc: doc.data().desc,
            yt: doc.data().yt,
            mainimg: doc.data().mainpic,
            banner: doc.data().banner,
            guide: doc.data().guid,
            op: doc.data().op,
            order: doc.data().order,
          }))
        );
      });
  }, []);

  return (
    <div className="designs">
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "sticky" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {!editting ? "Add New Design" : `Editting ${titleinp}`}
            </Typography>
            <Button autoFocus onClick={savetodb} color="secondary">
              {!editting ? "Add" : "save"}
            </Button>
          </Toolbar>
        </AppBar>
        <div className="designformcont">
          <Typography style={{ marginLeft: 0 }} variant="h6" component="div">
            MAIN PICTURE
          </Typography>

          <input
            onChange={mainhandleImageAsFile}
            accept="image/*"
            type="file"
            id="mainpic"
            name="mainpic"
          />
          <div className="imgupload">
            <label for="mainpic">
              <div
                className="uploadbtn mainpic"
                style={{
                  backgroundImage: 'url("' + mainimageAsUrl.imgUrl + '")',
                }}
              >
                {mainbtn}
              </div>
            </label>
          </div>
          <br />
          <br />
          <TextField
            value={orderinp}
            onChange={handleorderchange}
            id="order"
            type="number"
            label="Priority"
            variant="standard"
          />
          <TextField
            value={titleinp}
            onChange={handletitlechange}
            id="title"
            label="Title"
            variant="standard"
          />
          <TextField
            value={descinp}
            onChange={handledescchange}
            multiline
            rows={2}
            id="description"
            label="Description"
            variant="standard"
          />

          <input
            onChange={handleImageAsFile}
            accept="image/*"
            type="file"
            id="myfile"
            name="myfile"
          />

          <div className="imgupload">
            <label for="myfile">
              <div
                className="uploadbtn"
                style={{ backgroundImage: 'url("' + imageAsUrl.imgUrl + '")' }}
              >
                {btn}
              </div>
            </label>
          </div>
          <br />
          <br />
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            OUR GUIDE ON HOW TO TIE
          </Typography>
          <input
            onChange={guidehandleImageAsFile}
            accept="image/*"
            type="file"
            id="myfileguide"
            name="myfileguide"
          />

          <div className="imgupload">
            <label for="myfileguide">
              <div
                className="uploadbtn"
                style={{
                  backgroundImage: 'url("' + guideimageAsUrl.imgUrl + '")',
                }}
              >
                {guidebtn}
              </div>
            </label>
          </div>
          <br />
          <br />
          <TextField
            value={ytinp}
            onChange={handleytchange}
            id="yt"
            label="Youtube video id"
            variant="standard"
          />
        </div>
        <Typography
          sx={{ ml: 2, flex: 1 }}
          style={{ marginTop: 50 }}
          variant="h6"
          component="i"
        >
          RECOMMENDED BOWS AND TIES
        </Typography>

        <div className="multiimageuploader">
          {opimageAsUrl.map((imageAsUrl, index) => {
            if (imageAsUrl.imgUrl && imageAsUrl.imgUrl !== "") {
              return (
                <div className="multiimgntitle ">
                  <div className="imgupload ">
                    <label>
                      <div
                        className="uploadbtn multiimg"
                        style={{
                          backgroundImage: 'url("' + imageAsUrl.imgUrl + '")',
                        }}
                      >
                        <IconButton onClick={() => deleteitem(index)}>
                          <CancelRounded />
                        </IconButton>
                      </div>
                    </label>
                  </div>
                  <TextField
                    value={imageAsUrl.title}
                    onChange={(e) => handlemtchange(e, index)}
                    className="optitle"
                    label="title"
                    variant="standard"
                  />
                  <input
                    onChange={(e) => handleccImageAsFile(e, index)}
                    accept="image/*"
                    type="file"
                    className="myfilecc"
                    id={`myfilecc${index}`}
                    name={`myfilecc${index}`}
                  />
                  <div className="imgupload">
                    <label for={`myfilecc${index}`}>
                      <div
                        className="uploadbtn guidemi"
                        style={{
                          backgroundImage: 'url("' + imageAsUrl.ccimgurl + '")',
                        }}
                      >
                        {imageAsUrl.ccimgurl ? (
                          ""
                        ) : (
                          <AddPhotoAlternate className="uploadicon" />
                        )}
                      </div>
                    </label>
                  </div>
                </div>
              );
            }
          })}

          <input
            onChange={ophandleImageAsFile}
            accept="image/*"
            type="file"
            id="opmyfile"
            name="opmyfile"
          />

          <div className="imgupload">
            <label for="opmyfile">
              <div className="uploadbtn multiimgnew">{opbtn}</div>
            </label>
          </div>
        </div>
      </Dialog>

      <div className="designs_header">
        <h1>All Designs</h1>
        <Button color="primary" onClick={handleClickOpen} variant="contained">
          New
        </Button>
      </div>
      <Divider />
      <div className="designs_body">
        {products.map((product) => (
          <div className="eachdegincont">
            <div className="productsleft">
              <img src={product.mainimg} height="auto" width="150px" />
              {product.title}
              <br />
              {product.order}
            </div>
            <div className="productsbtn">
              <Button
                variant="text"
                onClick={() => {
                  setedditing(product.id);
                  settitleinp(product.title);
                  setdescinp(product.desc);
                  setImageAsUrl({ imgUrl: product.banner });
                  setmainImageAsUrl({ imgUrl: product.mainimg });
                  setytinp(product.yt);
                  setguideImageAsUrl({ imgUrl: product.guide });
                  setopImageAsUrl(product.op);
                  setorderinp(product.order);
                  setOpen(true);
                }}
              >
                Edit
              </Button>
              <Button variant="text" onClick={() => deleteproduct(product.id)}>
                Delete
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Designs;
