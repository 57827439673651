import {
  ArrowForwardIosRounded,
  FavoriteBorderOutlined,
  FavoriteRounded,
} from "@mui/icons-material";
import { Dialog, IconButton, Slide } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import Slider, { slickNext } from "react-slick";
import db from "../func/db";
import "./main.css";
import Cookies from "universal-cookie";
import Product from "./Product";
import lo from "./left.png";
import ro from "./right.png";
import Badge from "@mui/material/Badge";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Link, useLocation } from "react-router-dom";
import Tutorial from "./Tutorial";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Main() {
  const [showtutorial, setshowtutorial] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [products, setproducts] = useState([]);
  const [favs, setfavs] = useState([]);
  const [open, setOpen] = React.useState(false);

  const [current, setcurrent] = React.useState(0);

  const handleClose = () => {
    setOpen(false);
  };
  const sliderRef = useRef();

  const handlenavclick = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const checkfavcookie = () => {
    const cookies = new Cookies();
    const favcookie = cookies.get("favs", { path: "/" });
    if (favcookie) {
      const favcookielist = favcookie.split(",");
      setfavs([...favcookielist]);
    }
  };

  useEffect(() => {
    checkfavcookie();
  }, []);
  const settings = {
    initialSlide: 0,
    pauseOnHover: false,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "-60px",
    slidesToShow: windowDimensions.width > 1024 ? 5 : 3,
    speed: 500,
    autoplay: false,
    swipeToSlide: true,
    swipe: true,
    focusOnSelect: false,
    arrows: false,
    afterChange: (current) => setcurrent(current),
  };
  useEffect(() => {
    db.collection("products")
      .orderBy("order", "asc")
      .onSnapshot((snapshot) => {
        setproducts(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            desc: doc.data().desc,
            yt: doc.data().yt,
            mainimg: doc.data().mainpic,
            banner: doc.data().banner,
            guide: doc.data().guid,
            op: doc.data().op,
            order: doc.data().order,
          }))
        );
      });
    const cookies = new Cookies();
    const tutorialcookie = cookies.get("tutorial");
    if (!tutorialcookie) {
      setshowtutorial(true);
      cookies.set("tutorial", "shown", {
        path: "/",
        expires: new Date(Date.now() + 60000000000),
      });
    }
  }, []);

  const addtofavorite = (id) => {
    const cookies = new Cookies();
    const favcookie = cookies.get("favs", { path: "/" });

    if (favcookie) {
      if (favcookie.split(",").indexOf(id) > -1) {
        const temocoo = favcookie.split(",");
        temocoo.splice(favcookie.split(",").indexOf(id), 1);
        cookies.set("favs", temocoo.join(","), { path: "/" });
        const temp = [...favs];
        temp.splice(temp.indexOf(id), 1);
        setfavs([...temp]);
      } else {
        cookies.set("favs", favcookie + `,${id}`, { path: "/" });
        const temp = [...favs];
        temp.push(id);
        setfavs([...temp]);
      }
    } else {
      cookies.set("favs", id, { path: "/" });
      const temp = [...favs];
      temp.push(id);
      setfavs([...temp]);
    }
  };
  return (
    <div className="slidercont" onMouseEnter={() => checkfavcookie()}>
      {showtutorial && <Tutorial closefunc={setshowtutorial} />}
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Product handleClose={handleClose} page="main" prid={open} />
      </Dialog>

      <div className="navbtns">
        <IconButton
          onClick={() => {
            sliderRef.current.slickPrev();
          }}
        >
          <ArrowBackIosNewRoundedIcon fontSize="large" color="primary" />
        </IconButton>
        <IconButton
          onClick={() => {
            sliderRef.current.slickNext();
          }}
        >
          <ArrowForwardIosRounded fontSize="large" color="primary" />
        </IconButton>
      </div>
      <div className="overlays">
        <img src={lo} />
        <img src={ro} />
      </div>
      <Slider {...settings} ref={sliderRef}>
        {products.map((product, index) => (
          <div className="productslidecont" key={product.id}>
            <IconButton
              className="prlikebtn"
              onClick={() => addtofavorite(product.id)}
              aria-label="like"
              color="error"
            >
              {favs.indexOf(product.id) > -1 ? (
                <FavoriteRounded />
              ) : (
                <FavoriteBorderOutlined />
              )}
            </IconButton>
            <img className="primg" loading="lazy" src={product.mainimg} />
            <div className="producttext">
              <h2 className="producttitle">
                <b>{product.title}</b>
              </h2>
              <p>
                {product.desc.substring(0, 64)}
                {product.desc.length >= 64 && `...`}
              </p>
              <Link
                target="_blank"
                to={{
                  pathname: `/${product.id}`,
                }}
              >
                <div className="learnmorebtn">
                  <i>Learn More</i>
                </div>
              </Link>
            </div>
          </div>
        ))}
      </Slider>
      <p className="tagnavmobile">
        <span
          className={current < 1 ? "tagnavE tagnavEhighlight" : "tagnavE"}
          onClick={() => {
            handlenavclick(0);
          }}
        >
          BOTTOMS
        </span>
        |
        <span
          className={
            current < 12 && current >= 1
              ? "tagnavE tagnavEhighlight"
              : "tagnavE"
          }
          onClick={() => {
            handlenavclick(1);
          }}
        >
          BLOUSES
        </span>
        |
        <span
          className={
            current < 33 && current >= 12
              ? "tagnavE tagnavEhighlight"
              : "tagnavE"
          }
          onClick={() => {
            handlenavclick(12);
          }}
        >
          TOPS
        </span>
        |
        <span
          className={
            current < 38 && current >= 33
              ? "tagnavE tagnavEhighlight"
              : "tagnavE"
          }
          onClick={() => {
            handlenavclick(33);
          }}
        >
          EMBELLISHMENTS
        </span>
        |
        <span
          className={current >= 38 ? "tagnavE tagnavEhighlight" : "tagnavE"}
          onClick={() => {
            handlenavclick(38);
          }}
        >
          HAIRSTYLES
        </span>
      </p>

      <div className="btmnav">
        <div>
          <p className="tagnav">
            <span
              className={current < 1 ? "tagnavE tagnavEhighlight" : "tagnavE"}
              onClick={() => {
                handlenavclick(0);
              }}
            >
              BOTTOMS
            </span>
            |
            <span
              className={
                current < 12 && current >= 1
                  ? "tagnavE tagnavEhighlight"
                  : "tagnavE"
              }
              onClick={() => {
                handlenavclick(1);
              }}
            >
              BLOUSES
            </span>
            |
            <span
              className={
                current < 33 && current >= 12
                  ? "tagnavE tagnavEhighlight"
                  : "tagnavE"
              }
              onClick={() => {
                handlenavclick(12);
              }}
            >
              TOPS
            </span>
            |
            <span
              className={
                current < 38 && current >= 33
                  ? "tagnavE tagnavEhighlight"
                  : "tagnavE"
              }
              onClick={() => {
                handlenavclick(33);
              }}
            >
              EMBELLISHMENTS
            </span>
            |
            <span
              className={current >= 38 ? "tagnavE tagnavEhighlight" : "tagnavE"}
              onClick={() => {
                handlenavclick(38);
              }}
            >
              HAIRSTYLES
            </span>
          </p>
        </div>
        <div className="favnav">
          <div
            className="backbtnproduct productPageActionBuy"
            onClick={() =>
              (window.location = "https://josnsof.com/products/valentinis")
            }
          >
            <p className="backtext">Shop Now</p>
          </div>

          <Link to="/favourites" target="_blank">
            <Badge
              className="iconav"
              color="primary"
              badgeContent={favs.length}
              showZero
            >
              <FavoriteRounded color="error" />
            </Badge>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Main;
