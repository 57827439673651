import { DeleteRounded } from "@mui/icons-material";
import React, { createRef, useEffect } from "react";
import { useState } from "react";
import db from "../func/db";
import "./Dashboard.css";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function Access() {
  const [rows, setrows] = useState([]);
  const [cols] = useState({
    columns: [
      { field: "email", headerName: "Email", width: 300, editable: false },
      { field: "fname", headerName: "First Name", width: 200, editable: false },
      { field: "lname", headerName: "Last Name", width: 200, editable: false },
      {
        field: "checkbox",
        headerName: "Allow promo emails",
        width: 200,
        editable: false,
      },
      { field: "time", headerName: "Timestamp", width: 400, editable: false },
    ],
  });

  const [indb, setindb] = useState([]);
  useEffect(() => {
    db.collection("access")
      .orderBy("timestamp", "desc")
      .onSnapshot((snapshot) => {
        setrows(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            email: doc.data().value,
            fname: doc.data().fname,
            lname: doc.data().lname,
            checkbox: doc.data().checkbox,
            time: doc.data().timestamp.toDate().toString(),
          }))
        );
      });
  }, []);

  const [inp, setinp] = useState("");
  const valchange = (e) => {
    setinp(e.target.value);
  };
  const adduser = () => {
    db.collection("access").add({ value: inp });
    setinp("");
  };
  const deluser = (id) => {
    db.collection("access").doc(id).delete();
  };
  return (
    <div className="accesscont">
      <br />
      <br />
      <br />
      <br />
      <h1>Email list</h1>
      <div className="accesscardcont">
        {rows ? (
          <div style={{ height: "70vh", width: "100%" }}>
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              {...cols}
              {...{ rows: [...rows] }}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Access;
